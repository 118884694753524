.primaryRating-options {
  width: 100%;

  & > div {
  }

  $icon-size: 42px;
  .btn.btn-variant-default.btn-color-primary.primaryRating-option {
    font-size: $icon-size;
    padding: 1rem !important;
    width: auto;
    height: auto;
    background: transparent;
    opacity: 0.8;
    &.selected {
      opacity: 1;
    }
    &-like {
      background-color: rgba(var(--color-green-rgb), 0.3);
      &.selected {
        background-color: rgba(var(--color-green-rgb), 0.6);
      }
    }
    &-dislike {
      background-color: rgba(var(--color-red-rgb), 0.2);
      &.selected {
        background-color: rgba(var(--color-red-rgb), 0.6);
      }
    }
    &-neutral {
      background-color: rgba(var(--color-yellow-rgb), 0.2);
      &.selected {
        background-color: rgba(var(--color-yellow-rgb), 0.6);
      }
    }
    .fa-icon {
      display: block;
      width: $icon-size;
      height: $icon-size;
    }
  }
}

.generic-rating-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  overflow: auto;
  padding: 1rem;
  padding-top: max(env(safe-area-inset-top), 1rem);
  padding-bottom: max(env(safe-area-inset-bottom), 1rem);
}
