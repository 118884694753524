// .screen {
//   padding-top: env(safe-area-inset-top);
//   padding-bottom: env(safe-area-inset-bottom);
//   .screen-header {
//     padding-top: env(safe-area-inset-top);
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     z-index: 9999;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//     .screen-header-inner {
//       display: flex;
//       align-items: center;
//       height: 64px;
//       padding: 0 1rem;
//       i {
//         font-size: 1.5rem;
//       }
//       .screen-header-left,
//       .screen-header-right {
//         flex: 1 0 20%;
//         display: flex;
//         align-items: center;
//       }
//       .screen-header-title {
//         flex: 1 0 60%;
//         text-align: center;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         h1 {
//           font-size: 1.5rem;
//         }
//       }
//       .screen-header-right {
//         justify-content: flex-end;
//       }
//     }
//   }

//   .dark & .screen-header {
//     background-color: #000;
//   }

//   .screen-content {
//     padding: 1rem;
//   }

//   &.has-header {
//     .screen-content {
//       padding-top: calc(64px + 1rem);
//     }
//   }

//   &.tabbar-is-visible {
//     .screen-content {
//       padding-bottom: calc(64px + 1rem);
//     }
//   }
// }

.screen {
  .toolbar-bottom {
    z-index: 9999;
  }
  &.has-no-header {
    .screen-content {
      // padding-top: max(env(safe-area-inset-top), 1rem);
    }
  }
  .screen-content {
    padding: 1rem;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    // padding-bottom: calc(64px + 1rem);
  }
}

.screen-bottom-toolbar {
  z-index: 9999;
}
