.chip-radio-options {
  margin: 0 0 0.5rem;
  .chip-radio-option {
    margin-right: 0.25rem;
    &.chip-color-neutral.chip-variant-default {
      border: 1px solid transparent;
      background-color: #fff;
      color: var(--card-bg-color);
    }
    &_custom {
      input {
        width: 100%;
        padding: 0 0.5rem;
        height: 28px;
        font-size: 14px;
        background: transparent;
      }
    }
  }
}
