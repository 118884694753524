.gaggl .textarea-wrapper {
  textarea {
    min-height: 100px;
  }
  &.has-footer {
    textarea {
      min-height: 80px;
      margin-bottom: 0;
      border-radius: 10px 10px 0 0;
    }
    .textarea-footer {
      background: rgb(14, 35, 45);
      bottom: 0;
      width: 100%;
      padding: 0.5rem;
      border-radius: 0 0 10px 10px;
      .btn {
        padding: 0.5rem 1rem;
        width: auto;
        border-radius: 10px;
      }
    }
  }
}
