.primary-critique-count-chips {
  &.hide-counts {
    .chip {
      padding-right: 0;
    }
  }
  .chip {
    filter: grayscale(0.6);
    background: rgba(255, 255, 255, 0.1);
  }
  .more-click-chip {
    .chip-label {
      span {
        color: var(--f7-theme-color);
      }
      padding-left: 0.25rem;
      width: 100%;
    }
  }
  .chip-media {
    & > div {
      color: var(--f7-theme-color);
      background-color: rgba(var(--f7-theme-color-rgb), 0.2);
      width: 100%;
      border-radius: 500px;
    }
  }
}
