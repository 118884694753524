.chip {
  &.chip-variant-default {
    &.chip-color-default {
      --f7-chip-bg-color: var(--card-bg-color);
      --f7-chip-text-color: #fff;
      --f7-chip-outline-text-color: #fff;
      --f7-chip-outline-border-color: rgba(255, 255, 255, 0.12);
    }
    &.chip-color-neutral {
      --f7-chip-bg-color: #333;
      --f7-chip-text-color: #fff;
      --f7-chip-outline-text-color: #fff;
      --f7-chip-outline-border-color: rgba(255, 255, 255, 0.12);
    }
  }
  &.chip-variant-outlined {
    &.chip-color-default {
      --f7-chip-bg-color: #333;
      --f7-chip-text-color: #fff;
      --f7-chip-outline-text-color: #fff;
      --f7-chip-outline-border-color: #fff;
    }
    &.chip-color-neutral {
      //   --f7-chip-outline-border-color:
      --f7-chip-bg-color: #333;
      --f7-chip-text-color: #fff;
      --f7-chip-outline-text-color: #fff;
      --f7-chip-outline-border-color: rgba(255, 255, 255, 0.5);
    }
  }
  .notification-badge {
    --f7-badge-size: 10px;
    position: absolute;
    right: 2px;
    top: -3px;
  }
}
