@keyframes shadow-pulse-red {
  0%,
  100% {
    box-shadow: 0 0 0 0 transparentize(#ff3b30, 0.6);
  }
  50% {
    box-shadow: 0 0 0 5px transparentize(#ff3b30, 0.6);
  }
}

.btn {
  border: transparent;
  border-radius: 500px;
  font-weight: 600;
  color: #fff;
  padding: 10px 16px;
  --f7-theme-color: rgb(103, 58, 183);
  &.btn-roundness-rounded {
    border-radius: 10px;
  }
  &[disabled] {
    opacity: 0.5;
  }
  &.btn-size-sm {
    font-size: 12px;
    padding: 0.25rem 0.5rem;
  }
  &.btn-variant-default {
    &.btn-color-primary {
      background: rgb(103, 58, 183);
    }
    &.btn-color-neutral {
      color: #000; // TODO: Change if needed
    }
    &.btn-color-default {
      background: var(--card-bg-color);
    }
    &.btn-color-danger {
      background: var(--color-red);
      &.show-highlight {
        animation: shadow-pulse-red 1.25s infinite;
      }
    }
  }
  &.btn-variant-flat {
    background: transparent;
    &.btn-color-primary {
      --f7-theme-color: rgb(155, 105, 242);
      color: var(--f7-theme-color);
    }
  }
  &.btn-variant-smooth {
    &.btn-color-neutral {
      background: transparentize(#fff, 0.9);
    }
    &.btn-color-primary {
      color: rgb(155, 105, 242);
      background: rgba(103, 58, 183, 0.4);
    }
    &.btn-color-danger {
      --f7-theme-color: var(--color-red);
      color: var(--f7-theme-color);
      background: rgba(255, 59, 48, 0.2);
    }
  }
  &.btn-variant-outlined {
    background: transparent;
    &.btn-color-neutral {
      --f7-theme-color: #fff;
      color: var(--f7-theme-color);
      border: 1px solid var(--f7-theme-color);
    }
    &.btn-color-primary {
      --f7-theme-color: rgb(155, 105, 242);
      color: var(--f7-theme-color);
      border: 1px solid var(--f7-theme-color);
    }
    &.btn-color-danger {
      --f7-theme-color: var(--color-red);
      color: var(--f7-theme-color);
      border: 1px solid var(--f7-theme-color);
    }
  }
  .btn_start-icon {
    margin-right: 0.5rem;
  }
  .btn_end-icon {
    margin-left: 0.5rem;
  }
}
