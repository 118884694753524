.profile-screen-header {
  .letter-avatar {
    height: 120px;
    width: 120px;
    font-size: 5rem;
  }
  .display-name {
    position: relative;
    input {
      max-width: 140px;
    }
    .display-name-actions {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      button + button {
        margin-left: 0.5rem;
      }
    }
  }
}
