// .list {
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   .card & {
//     margin: -1rem 0;
//   }
//   .list-item {
//     display: flex;
//     padding: 1rem 0;
//     margin: 0;
//     border-bottom: 1px solid #333;
//     &:last-child {
//       border-bottom: none;
//     }
//     .list-item__text {
//       flex: 1;
//     }
//     .list-item__end-icon {
//       justify-self: flex-end;
//     }
//   }
// }

.list {
  &.ignore-screen-content-padding {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .item-title {
    font-weight: 500;
    color: var(--title-color);
    font-size: 15px;
  }
}
