.quick-picks-screen {
  //   $gradient-height: 100px;

  //   .toolbar-bottom {
  //     &:after {
  //       content: '';
  //       position: absolute;
  //       top: $gradient-height * -1;
  //       left: 0;
  //       width: 100%;
  //       height: $gradient-height;
  //       background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  //     }
  //   }

  //   .screen-content {
  //     padding-bottom: $gradient-height;
  //   }
  .toolbar-bottom {
    background-color: rgba(var(--f7-toolbar-bg-color-rgb, var(--f7-bars-bg-color-rgb)), 0.25);
    -webkit-backdrop-filter: saturate(180%) blur(2px);
    backdrop-filter: saturate(180%) blur(2px);
  }
  .card {
    margin: 0 0 1rem;
    .title-toolbar {
      margin: 0;
    }
  }
  // &_subnavbar {
  //   width: 100%;
  //   .title-toolbar {
  //     text-align: center;
  //     justify-content: center !important;
  //     .title {
  //       overflow: unset;
  //       white-space: wrap;
  //       line-height: 1.4;
  //     }
  //   }
  // }
}
