@import '../../../styles/mixins.scss';

.gaggl .card {
  background: rgb(14, 35, 45);
  border-radius: 1rem;
  overflow: hidden;
  margin: 0;
  img {
    max-width: 100%;
  }
  & + .card {
    margin-top: 1rem;
  }
  .card-header {
    flex-direction: column;
    padding: 1rem;
    .card-header-avatar {
      margin-right: 0.5rem;
      flex: 0 0 auto;
    }
    .line-clamp {
      overflow: hidden;
      h1 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .card-header-action {
      padding-left: 0.5rem;
    }
    h3 {
      font-size: 0.8rem;
      margin: 0;
      color: rgb(154, 169, 178);
      line-height: 1;
    }
    h1 {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
    }
    h2 {
      font-size: 0.8rem;
      margin: 0;
      color: rgb(154, 169, 178);
    }
    border: none;
  }
  .card-header + .card-body {
    padding-top: 0;
  }
  .card-body {
    padding: 1rem;
    p {
      margin: 0 0 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    & + .card-body {
      padding-top: 0;
    }
  }
  .card-footer {
    padding: 0 1rem 1rem;
  }
}
