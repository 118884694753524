.reviewed-header-wrapper {
  padding: 1rem 1rem 0;
  .card-body + & {
    padding: 0 1rem 1rem;
  }
  .reviewed-header {
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid transparentize(#fff, 0.5);
    & > span {
      position: absolute;
      display: inline-block;
      background: rgb(14, 35, 45);
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      left: 0.75rem;
      top: -8px;
      padding: 0 0.25rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      font-weight: 600;
    }
  }
}

.post-reactions {
  .icon-btn {
    font-size: 1.1rem;
    &.selected {
      background: transparentize(#fff, 0.5);
      span {
        filter: grayscale(0);
      }
    }
    // span {
    //   filter: grayscale(1);
    // }
  }
  .add-custom-reaction {
    i {
      font-size: 1rem;
      opacity: 0.8;
      color: #0e232d;
    }
  }
}

.reaction-text {
  .reaction-text_emoji-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reaction-text_emoji {
    position: relative;
    color: #fff;
    border: 1px solid rgb(14, 35, 45);
    border-radius: 500px;
    height: 21px;
    width: 21px;
    text-align: center;
    line-height: 21px;
    background: lighten(rgb(14, 35, 45), 5%);
    overflow: hidden;
    .reaction-text_emoji-bg {
      $blur: 2px;
      $size: 40px;
      position: absolute;
      height: $size;
      width: $size;
      top: calc((#{$size - 21}) / 2 * -1);
      left: calc((#{$size - 21}) / 2 * -1);
      font-size: $size;
      line-height: $size;
      filter: blur($blur);
      -webkit-filter: blur($blur);
      opacity: 0.3;
    }
    span {
      position: relative;
    }
    & + .reaction-text_emoji {
      margin-left: -0.4rem;
    }
  }
}

.post-card-wrapper {
  .card + &,
  & + .card,
  & + .post-card-wrapper {
    margin-top: 1rem;
  }
  .comments-wrapper {
    position: relative;
    margin-top: 1rem;
    padding-left: 1.5rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: transparentize(rgb(103, 58, 183), 0.5);
      border-radius: 500px;
    }
  }
}

.gaggl .post-card {
  .card-header {
    align-items: center !important;
    h1 {
      font-size: 1rem;
      margin: 0;
    }
    h2 {
      font-size: 0.6rem;
    }
  }

  .event-card {
    // border: 1px solid transparentize(#fff, 0.5);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .textarea-wrapper {
    margin-top: 1rem;
  }
  textarea {
    color: #fff;
  }
  textarea,
  .textarea-footer {
    background: rgb(3, 17, 24) !important;
  }
}
