@mixin line-clamp($font-size, $line-height, $lines-to-show) {
  @if unitless($line-height) == false {
    $line-height: create-unitless-line-height($font-size, $line-height);
  }

  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  font-size: $font-size;
  height: ($font-size * $line-height * $lines-to-show); // Fallback for non-webkit browsers
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}
