.source-logs-page {
  background: #f8f8f8;
  padding: calc(1rem + env(safe-area-inset-top)) 0 calc(1rem + env(safe-area-inset-bottom));
  .container {
    max-width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 0.5rem;
  }
  .table-wrapper {
    overflow-x: auto;
  }
  table {
    border: none;
    border-collapse: collapse;
    max-width: 100%;
    tr {
      &:hover {
        td {
          background: rgba(0, 0, 0, 0.1);
        }
      }
      th,
      td {
        text-align: left;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
      }
      .text-col {
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }

  .current-row-drawer {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    width: 100%;
    max-width: 480px;
    padding: calc(1rem + env(safe-area-inset-top)) 1rem calc(5rem + env(safe-area-inset-bottom));
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    pre {
      margin-top: 1rem;
      background: #e8e8e8;
      color: rgba(255, 0, 0, 0.7);
      overflow-x: auto;
      padding: 1rem;
      border-radius: 0.25rem;
    }
    .fine-tune-event {
      border: 1px solid #e8e8e8;
      padding: 0.5rem;
      border-radius: 0.25rem;
      margin-bottom: 1rem;
    }
    .custom-checkbox {
      padding: 0.5rem;
      font-weight: 600;
      input {
        margin: 0 0.5rem 0 0;
      }
    }
    .current-row-drawer_footer {
      background: #fff;
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 1rem 1rem calc(1rem + env(safe-area-inset-bottom));
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 480px;
      width: 100%;
      .btn {
        padding: 0.75rem 1rem;
        display: inline-block;
        width: auto;
        & + .btn {
          margin-left: 0.5rem;
        }
      }
      .save-btn {
        padding: 0.75rem 2rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .current-row-drawer {
      max-width: 100vw;
    }
    th,
    td {
      display: none;
    }
    .has-event-col,
    .text-col {
      display: table-cell;
    }
  }
}
