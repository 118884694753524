.trivia-question {
  .trivia-question_time-remaining {
    background: linear-gradient(270deg, rgb(83, 31, 172) 20%, rgb(24, 78, 240) 100%);
    font-size: 4rem;
    font-weight: 900;
    color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    &.text-danger {
      color: transparentize(#ff0000, 0.2) !important;
    }
  }
  .trivia-question_question-card {
    background: #fff;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
    .trivia-question_question {
      background: linear-gradient(270deg, rgb(83, 31, 172) 20%, rgb(24, 78, 240) 100%);
      padding: 1rem;
      border-radius: 0.5rem;
      color: #fff;
      // .blur-text {
      //   filter: blur(0.5rem);
      // }
    }
    .trivia-question_question-option {
      border-color: #d7d7d7;
      background: #d7d7d7;
      color: #0d1424;
      font-weight: 900;
      text-transform: uppercase;
      &:hover,
      &:focus {
        // background: linear-gradient(270deg, rgb(83, 31, 172) 20%, rgb(24, 78, 240) 100%);
        // border-color: transparent;
        // color: #fff;
        border-color: #d7d7d7;
        background: #d7d7d7;
        color: #0d1424;
      }
      &.is-correct {
        background: linear-gradient(109.6deg, rgb(58, 181, 46) 11.2%, rgb(0, 204, 130) 91.7%);
        color: #fff;
      }
      &.is-incorrect {
        background: linear-gradient(108.4deg, rgb(176, 2, 12) 3.3%, rgb(253, 44, 56) 98.4%);
        color: #fff;
      }
    }
  }
  .btn.trivia-question_next-btn {
    background: linear-gradient(270deg, rgb(83, 31, 172) 20%, rgb(24, 78, 240) 100%);
    border-color: transparent;
    color: #fff;
    box-shadow: none;
  }
}
