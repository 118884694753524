.title-toolbar {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  &:first-child {
    margin-top: 0;
  }
  &.title-toolbar-size-lg {
    .title {
      font-size: 34px;
      line-height: 1;
      margin: 0.5rem 0;
    }
  }
  &.title-toolbar-size-md {
    .title {
      font-size: 18px;
    }
  }
  &.title-toolbar-size-sm {
    .title {
      font-size: 16px;
    }
  }
  .title {
    font-weight: 600;
    color: #fff;
    margin: 0;
  }
  .title-action {
  }
}
