.gaggl-admin-app {
  font-family: 'Inter', sans-serif !important;
  * {
    box-sizing: border-box;
  }

  input[type='text'],
  select {
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin: 0 0 0.5rem;
    width: 100%;
  }
}
