.custom-modal-backdrop,
.actions-backdrop,
.dialog-backdrop,
.popover-backdrop,
.popup-backdrop,
.preloader-backdrop,
.sheet-backdrop,
.fab-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.dark .popup .page {
  background-color: #111;
}

.popup .popup-content {
  padding: 0 1rem 1rem;
}

.popup-height-half {
  top: 50%;
  height: 50%;
  border-radius: 2rem;
  .page-content {
    padding-top: 1.5rem;
  }
}

.popup-height-full {
  .popup-content {
    padding-top: 1rem;
  }
}

.popup-height-3quarters {
  top: 25%;
  height: 75%;
  border-radius: 2rem;
  .page-content {
    padding-top: 1.5rem;
  }
}

.swipe-handler {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 0;
  & > div {
    height: 0.25rem;
    width: 100px;
    border-radius: 500px;
    background-color: #e0e0e0;
    display: block;
    margin: 0 auto;
  }
}
