.icon-btn {
  width: 32px;
  height: 32px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.icon-btn-size-lg {
    width: 48px;
    height: 48px;
    font-size: 1.4rem;
  }

  .la,
  .las {
    font-size: 1.2rem;
  }
  .badge {
    $badge-size: 10px;
    position: absolute;
    display: block;
    top: -2px;
    right: -2px;
    height: $badge-size;
    width: $badge-size;
    min-width: 0;
  }
  & > span {
    display: block;
    i {
      display: block;
    }
  }
}
