.details-chart-section {
  &_pie-chart-wrapper {
    width: 100px;
  }
  &_reason-column {
    overflow: hidden;
    width: 100%;
    .card {
      height: 100%;
    }
    .empty-state-wrapper {
      border: none;
      .empty-state {
        padding: 0;
        p {
          margin: 0 0 0.5rem;
        }
        .btn {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
  &_reason-row {
    overflow: hidden;
    & + .details-chart-section_reason-row {
      margin-top: 0.3rem;
    }
  }
  &_reason-label-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
  }
  &_reason-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
