@import '../../styles/mixins.scss';

$promoter-img-size: 32px;
.event-promoters {
  padding: 0 0 1rem;
  & > div {
    height: 32px;
  }
  .event-card & {
    padding: 1rem;
  }
  h3 {
    font-size: 0.9rem;
    margin: 0;
    .horizontal-scroll-item & {
      @include line-clamp(0.9rem, 1.4, 1);
    }
  }
  .promoter-img {
    height: $promoter-img-size;
    width: $promoter-img-size;
    border: 1px solid #fff;
    border-radius: 50%;
    .letter-avatar {
      height: $promoter-img-size - 2;
      width: $promoter-img-size - 2;
    }
  }
  .event-promoters_following {
    & > div {
      & > strong {
        font-size: var(--f7-card-font-size);
        opacity: 0.7;
      }
    }
  }
}

.event-header {
  padding: 0 0 1rem;
  .event-card & {
    padding: 0 1rem 1rem;
    &:first-child {
      padding-top: 1rem;
    }
  }
  h1 {
    font-size: 19px;
    line-height: 21px;
    font-weight: 500;
    margin: 0 0 0.25rem;
    .horizontal-scroll-item & {
      @include line-clamp(1rem, 1.1, 2);
    }
  }
  h2 {
    font-size: 0.8rem;
    margin: 0;
    color: #9aa9b2;
    & > div {
      @include line-clamp(0.8rem, 1.5, 1);
    }
  }
}

.event-img-wrapper {
  padding: 0 0 1rem;
  .event-card & {
    padding: 0 1rem 1rem;
  }
  .event-img {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    .bg-img {
      $blur: 20px;
      position: absolute;
      filter: blur($blur);
      -webkit-filter: blur($blur);
      object-fit: cover;
      width: auto;
      height: auto;
      width: 100%;
      height: 100%;
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    .primary-img {
      max-width: 100%;
      @media (min-width: 768px) {
        position: relative;
        z-index: 9;
        display: block;
        margin: 20px auto;
        max-height: 475px;
        //   box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      }
    }
    .horizontal-scroll-item & {
      .primary-img {
        height: 240px;
        object-fit: contain;
        margin: 0.5rem auto;
        display: block;
        position: relative;
        z-index: 9;
        border-radius: 1rem;
      }
      .event-img-placeholder {
        background: lighten(rgb(14, 35, 45), 5%);
        height: 256px;
        width: 100%;
        border-radius: 0.5rem;
      }
      .bg-img {
        display: block;
      }
    }
  }
}

.event-actions {
  padding: 0 0 1rem;
  .event-card & {
    padding: 0 1rem 1rem;
  }
  .horizontal-scroll-item & {
    display: none;
  }
}

.horizontal-scroll.item-count-1 {
  .event-card {
    padding: 0;
    // width: calc(100vw - 2rem);
  }
}
