.ig-post {
  & > div {
    flex: 1 1 auto;
  }
  .ig-post_img-wrapper {
    width: 120px;
    flex: 0 0 auto;
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      width: 100%;
      border-radius: 10px;
    }
  }
  .ig-post_description-wrapper {
    p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  .btn {
    white-space: nowrap;
  }
}
