.card.suggested-event-card {
  .card-body {
    max-height: 515px;
    overflow: auto !important;
    padding-bottom: 50px;
  }
  .card-footer {
    padding-top: 1rem;
  }
  .suggested-event-card_gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--card-bg-color) 100%);
    height: 50px;
    position: absolute;
    top: -49px;
    left: 0;
    right: 0;
  }
}
