#quick-picks-onboarding-transition {
  position: fixed;
  z-index: 9000;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  & > div {
    width: 100%;
  }
}
