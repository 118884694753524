.onboarding-interests-screen {
  .title-toolbar-upper {
    margin-bottom: 0;

    & + .title-toolbar {
      margin-top: 0;
    }
  }
  .f7-tabbar {
    box-shadow: 0 0 25px 25px #121212;
  }
}
