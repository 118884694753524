.gaggl {
  .sign-in-page {
    padding: 16px;
    overflow-y: hidden;
    & > .container {
      padding: 1rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      & > .row {
        margin: 0;
        & > div {
          border-radius: 4px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .sign-in-from {
    padding: 0 1rem;
  }
}

.slide-img-wrapper {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.8);
  &-active {
    background: var(--f7-theme-color);
  }
}

.PhoneInput {
  padding: 0 1rem;
  background-color: var(--f7-input-bg-color);
  border-radius: 10px;
  .input {
    flex: 1 1 auto;
  }
}

.login-popup {
  &_icon {
    color: var(--f7-theme-color);
    font-size: 3rem;
    margin: 2rem 0 0;
    display: block;
    & + p {
      margin-top: 0;
    }
  }
  .code-field {
    font-size: 2.5rem;
    letter-spacing: 1.5rem;
    text-align: center;
  }
}
