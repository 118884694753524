.current-user-city {
  padding: 0.4rem 1rem;
}

.title-toolbar-welcome {
  margin: 0;
  & + .title-toolbar {
    margin-top: 0;
  }
}

.interests-section {
  & > div {
    &:last-child {
      margin-bottom: 0;
      .chip {
        margin-bottom: 0;
      }
    }
  }
  .interests-section_btn-wrapper,
  .chip {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
