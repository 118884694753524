.instagram-accounts-page {
  table {
    tr:nth-child(even) {
      background: #e8e8e8;
    }
    td {
      padding: 0.25rem 0.5rem;
    }
  }
  button {
    padding: 0.5rem 1rem;
  }
  .current-city-overlay {
    position: fixed;
    inset: 0;
    background: #fff;
    overflow: auto;
    padding: 5rem 1rem 1rem;
    .current-city-overlay-header {
      position: fixed;
      background: #fff;
      width: 100%;
      top: 0;
      left: 0;
      padding: 1rem;
      padding-top: calc(env(safe-area-inset-top) + 1rem);
      display: flex;
      justify-content: space-between;
      h2 {
        margin: 0;
      }
      button {
        width: 81px;
      }
    }
  }

  .current-city-overlay-tabs {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem;
    gap: 0.5rem;
  }

  .city-venue-row {
    margin: 0 0 1rem;
    .city-venue-row-title {
      margin: 0 0 0.5rem;
      span {
        display: inline-block;
        margin-left: 1rem;
        font-size: 0.8rem;
      }
    }
    .city-venue-row-instagram {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      input {
        margin: 0;
      }
      button {
        width: 81px;
      }
    }
  }

  .create-instagram-account-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    input,
    select,
    button {
      margin: 0;
    }
    input {
    }
    select {
      width: 120px;
    }
    button {
      width: 81px;
    }
  }

  .instagram-account-row {
    h3 {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        select {
          margin: 0 1rem 0 0;
        }
      }
    }
  }
  .instagram-overlay {
    position: fixed;
    inset: 0;
    background: '#fff';
    iframe {
      height: calc(100% - 2rem - env(safe-area-inset-bottom));
      width: 100%;
    }
    button {
      position: absolute;
      bottom: env(safe-area-inset-bottom);
    }
  }
}
