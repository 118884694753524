.list.notification-list {
  margin: 1rem -0.5rem;
  .item-title {
    white-space: normal;
    line-height: 1.3;
    .item-footer {
      margin-top: 0.25rem;
    }
  }
}

.notification-media {
  position: relative;
  .notification-unread-icon {
    color: #f50057;
    position: absolute;
    font-size: 10px;
    line-height: 1;
    left: -16px;
    top: 50%;
    margin-top: -5px;
  }
}
