#user-toast-notifcation {
  position: fixed;
  z-index: 999999;
  width: 100%;
  & > div {
    padding: 1rem;
    padding-top: calc(1rem + env(safe-area-inset-top));
  }
  .card {
    box-shadow: 0 0 15px 10px transparentize(black, 0.2);
    border-left: 5px solid var(--f7-theme-color);
  }
  .user-toast-notifcation_icon {
    font-size: 2rem;
  }
  .user-toast-notifcation_content {
    h3,
    p {
      margin: 0;
    }
  }
  .user-toast-notifcation_score {
    border: 1px solid transparentize(white, 0.5);
    border-radius: 0.25rem;
    padding: 0.1rem 1rem;
    margin: 0.6rem 0 0;
    thead {
      display: none;
    }
  }
}
