.gaggl {
  .sender-card,
  .system-card {
    display: flex;
    align-items: flex-end;
  }
  .sender-card {
    justify-content: flex-end;
    .card {
      background: rgb(33, 150, 243);
      color: #fff;
    }
    .letter-avatar {
      margin-left: 0.5rem;
    }
  }

  .system-card {
    justify-content: start;
    margin-top: 1rem;
    .card {
      background: rgb(14, 35, 45);
    }
    .letter-avatar {
      margin-right: 0.5rem;
    }
  }
}
