.explore-events_input-wrapper {
  width: 100%;
}

.explore-events_icon-wrapper {
  flex: 0 0 auto;
}

.date-options {
  & > div {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.title-toolbar-welcome {
  margin: 0;
  & + .title-toolbar {
    margin-top: 0;
  }
}

.interests-section {
  & > div {
    &:last-child {
      margin-bottom: 0;
      .chip {
        margin-bottom: 0;
      }
    }
  }
  .chip {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
