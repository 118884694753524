@keyframes shadow-pulse {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.15);
  }
  50% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15);
  }
}

.link-banner {
  &.show-highlight {
    animation: shadow-pulse 1.25s infinite;
  }
}
