.empty-state-wrapper {
  border: 1px solid transparentize(#fff, 0.5); // var(--f7-chip-outline-border-color);
  border-radius: 1rem;
  .empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    text-align: center;
    height: 100%;
    h4 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
    p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 0.25rem;
      }
    }
  }
}
