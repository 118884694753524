.horizontal-scroll {
  position: relative;
  margin: 0 -1rem;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 1rem;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    z-index: 999;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 1rem;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    z-index: 999;
  }
  .horizontal-scroll-inner {
    display: flex;
    overflow-x: auto;
    padding: 0 1rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &.item-count-1 {
    .horizontal-scroll-inner {
    }
    .horizontal-scroll-item {
      width: auto;
      min-width: 100%;
    }
  }
  .horizontal-scroll-item {
    width: 85%;
    flex: 0 0 auto;
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    & > * {
      height: 100%;
    }
  }
  .horizontal-scroll_before {
    position: absolute;
    width: 4rem;
    height: 100%;
    left: 0;
    background: linear-gradient(to right, #fff, transparent);
  }
}
