.letter-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
  width: 32px;
  height: 32px;
  text-transform: uppercase;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
