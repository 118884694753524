.core-tabbar.tabbar {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background-color: #fff;
  opacity: 0;
  &.tabbar-is-ready {
    opacity: 1;
  }
  &.tabbar-is-hidden {
    opacity: 0 !important;
    display: none !important;
  }
  .dark & {
    background-color: #000;
  }
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding-bottom: env(safe-area-inset-bottom);
  .tabbar-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
  }
  .tabbar-item {
    flex: 1 1 0;
    text-align: center;
    .fa {
      font-size: 1.5rem;
    }
    .las {
      font-size: 2rem;
    }
    .is-not-active {
      color: #a8a8a8;
    }
  }
}

.f7-tabbar {
  --f7-theme-color: rgb(155, 105, 242);

  .tabbar__icon {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 2px;
    position: relative;
    i {
      margin: 0;
    }
    .badge {
      --f7-badge-size: 10px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    .fa-mug-hot {
      transform: translateX(3px);
    }
  }

  .fa {
    font-size: 1.5rem;
  }
  .las {
    font-size: 2rem;
  }
  &.toolbar-bottom {
    .tab-link {
      font-size: 0.7rem;
    }
  }
}
