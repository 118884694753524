.onboarding-critiques-screen {
  .selected-venue-card-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    padding: 1rem;
    .card {
      width: 100%;
    }
  }
}

.onboarding-instructions {
  .chip {
    filter: grayscale(0.6);
    background: rgba(255, 255, 255, 0.1);
  }
  .chip-media {
    & > div {
      color: var(--f7-theme-color);
      background-color: rgba(var(--f7-theme-color-rgb), 0.2);
      width: 100%;
      border-radius: 500px;
    }
  }
}
