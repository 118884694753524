.venue-screen {
  .event-description {
    font-size: 16px;
  }

  .textarea-wrapper {
    margin-bottom: 1rem;
  }

  #venue-comments {
    margin-top: 1rem;
  }
}
